import dayjs from 'dayjs'

import { createAuthentication } from '@redwoodjs/auth'
import { navigate, routes } from '@redwoodjs/router'

type User = {
  id: number
  displayName: string
  mail: string
  eduPersonPrincipalName: string
  roles: string[]
  organisation?: { id: number; name: string; isActive: boolean }
}

type LoginOptions = {
  returnPage: string
  uqOnly?: boolean
}

const defaultLoginOptions = { uqOnly: false, returnPage: '/' }

type LocalAuthStore = {
  user: User
  sessionExpiry: Date
  token: string
}

type AuthClient = {
  login: (options: LoginOptions) => Promise<User | null>
  logout: () => Promise<unknown>
  getToken: () => Promise<string | null>
  getUserMetadata: () => Promise<User>
  signup: () => Promise<unknown>
  restoreAuthState: () => Promise<unknown>
}

const isSessionExpired = () => {
  const store = getAuthStore()
  return !store.sessionExpiry || dayjs(store.sessionExpiry).isBefore(dayjs())
}

const sessionExpiresSoon = () => {
  const store = getAuthStore()
  return dayjs(store.sessionExpiry).isBefore(dayjs().add(5, 'minutes'))
}

const isAuthenticated = () => {
  const store = getAuthStore()
  return !!store.user && !isSessionExpired()
}

const getAuthStore = (): LocalAuthStore => {
  const authStore = JSON.parse(localStorage.getItem('UQRDM_AUTH_STORE')) as LocalAuthStore
  if (!authStore) return { user: null, sessionExpiry: null, token: null }
  return authStore
}

const client: AuthClient = {
  login: async ({ uqOnly, returnPage }: LoginOptions = defaultLoginOptions) => {
    console.dir("ATTEMPTING TO LOGIN?")
    // Check if we are already authenticated. If so, just return the user
    if (isAuthenticated()) return getAuthStore().user

    // Not authenticated. However, we might have a UQ SSO cookie. Call auth to try and login
    const authResponse = await fetch(`${process.env.API_BASE_URL}/auth`, { credentials: 'include' })
    const body = await authResponse.json()
    if (!authResponse.ok) {
      // Login failed. Let's do something with this.
      if (body === 'UNKNOWN_USER') navigate('/unknown-user')
      else if (body === 'INACTIVE_USER') navigate('/inactive-user')
      else {
        // This could be anything. But we want to redirect the user to the login success page
        const returnUrl = routes.loginSuccess({ redirectTo: returnPage })
        const fullReturnUrl = window.btoa(process.env.WEB_BASE_URL + returnUrl)
        const loginUrl = uqOnly ? process.env.WEB_AUTH_SSO_UQ_LOGIN_PAGE : process.env.WEB_AUTH_SSO_LOGIN_PAGE

        window.location.href = `${loginUrl}?return=${fullReturnUrl}`
      }

      return null
    }

    // Login was successful. Store the auth details in local storage
    localStorage.setItem('UQRDM_AUTH_STORE', JSON.stringify(body))

    return body.user
  },
  logout: async () => {
    const token = getAuthStore().token
    await fetch(`${process.env.API_BASE_URL}/auth?logout=${token}`, { credentials: 'include' })
    await localStorage.clear()
    window.location.href = process.env.WEB_AUTH_SSO_LOGOUT_PAGE
  },
  getToken: async () => {
    return getAuthStore().token
  },
  getUserMetadata: async () => {
    return isAuthenticated() ? getAuthStore().user : null
  },
  restoreAuthState: async () => {
    if (isAuthenticated() && sessionExpiresSoon()) {
      // We are close to expiry. The session on the server might have been refreshed. Let's check
      console.error('WE ARE CLOSE TO EXPIRY. WHAT NOW??')
    }
  },
  signup: async () => {
    console.error('NOT IMPLEMENTED')
  },
}

function createAuth() {
  return createAuthentication({
    type: 'custom-auth',
    ...client,
  })
}

export const { AuthProvider, useAuth } = createAuth()
